import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import mawuli from "../../pages/../img/mawuli.jpg";
import DocumentTitle from '../../component/DocumentTitle';
// import { Container } from 'bootstrap';
const endPoint = process.env.REACT_APP_BASE_URL

const Blog = () => {
    DocumentTitle("Mawuli Stephen | Blog ")

    const [posts, setPosts] = useState([]);

    const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));

    const cat = useLocation().search

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${endPoint}posts${cat}`);
                setPosts(res.data);
                // console.log(res.data);
            } catch (err) {
                console.log('This is the Error', err);
            }
        };
        fetchData();
    }, [cat]);

    const getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
    }


    return (
        <div className="home">
            <div className="container  bg-blur-2" id="content">

                <div className="section herColor">
                    <div className="container">
                        <div className="hero">
                            <section className="hero-left">
                                <h1>Some pieces of words put together for your consumption</h1>
                                <p>
                                    Combined 10 years on working experience as a Marketing professional and Software developer
                                </p>
                                {/* <a href="./?cat=about-me">Learn More</a> */}
                            </section>

                            <section className="right">
                                <img src={mawuli} alt="Hero illustration" />
                            </section>
                        </div>
                    </div>

                </div>


                {/* hr */}

                <hr />
                <hr />
                <hr />

                <div className=" mb-4 subtitle" >

                    <h3 className="text-start">All Write-ups</h3>
                    {/* <a href="./blog">Read More</a> */}

                </div>
                
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3">

                    {sortedPosts.map((post) => (

                        <div key={post.id}>

                            {/* <Link to={`/posts/${post.id}`}> */}
                            <div className="blog-card content post" style={{ marginBottom: '20px' }} >
                                
                            {/* <Link to={`/posts/${post.id}`}>  
                            </Link> */}
                            <img className="blog-img" src={`${endPoint}uploads/${post.img}`} alt={post.title} />

                                <div className="text-overlay ">
                                    <h4 className="text-bold">{post.title.substring(0, 30)}</h4>
                                    <p>{getText(post.descrip).substring(0, 150)}</p>
                                    <Link to={`/posts/${post.id}`}><button className="primary" style={{ padding: '5px' }}>Read More</button ></Link>
                                </div>

                            </div>
                            {/* </Link> */}

                        </div>
                    ))}

                </div>


                {/* <div className="posts container ">
                    {sortedPosts.map((post) => (
                        <div className="post" key={post.id}>

                            <div className="img">
                                <img src={`${endPoint}uploads/${post?.img}`} alt={post.title} />

                            </div>
                            <div className="content bg-blur-2">
                                <Link className="link" to={`/posts/${post.id}`}>
                                    <h1>{post.title}</h1>
                                </Link>
                                <p> {getText(post.descrip)}</p>

                                <Link to={`/posts/${post.id}`}><button>Read More</button></Link>

                            </div>
                        </div>
                    ))}
                </div> */}


            </div>


        </div>

    );
};

export default Blog;