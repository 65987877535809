import React from "react";
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";
import DocumentTitle from '../../component/DocumentTitle';


const endPoint = process.env.REACT_APP_BASE_URL;

const Register = () => {
  DocumentTitle("Mawuli Stephen |Register")

  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${endPoint}auth/register`, inputs);
      navigate("/login");
    } catch (err) {
      setError(err.response.data);
    }
  };
  return (
    <div className="auth">
      <h1>Register</h1>
      <form action="">
        <input type="text" placeholder="Username" name="username" onChange={handleChange} />
        <input type="email" placeholder="email" name="email" onChange={handleChange} />
        <input type="password" placeholder="Password" name="password" onChange={handleChange} />
        <button onClick={handleSubmit}>Register</button>
        {err && <p className="error">{err}</p>}
        <span>Have an Account <Link to="/Login">Login</Link></span>
      </form>
    </div>
  )
}

export default Register