import axios from "axios";
import { createContext, useEffect, useState } from "react";

const API = process.env.REACT_APP_BASE_URL;

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  
  const login = async (inputs) => {
    const res= await axios.post(`${API}auth/login`, inputs, );
    setCurrentUser(res.data);
    // console.log(res.data);
  };

  const logout = async (inputs) => {
    try {
      await axios.post(`${API}auth/logout`);
      // await axios.post("/auth/logout");
      setCurrentUser(null);
    } catch (err) {
      console.error('Error during logout:', err);
    }

  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};


// import axios from "axios";
// import { createContext, useEffect, useState } from "react";

// const API = process.env.REACT_APP_BASE_URL;

// export const AuthContext = createContext();

// export const AuthContextProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);

//   const login = async (inputs) => {
//     try {
//       const res = await axios.post(`${API}auth/login`, inputs);
//       setCurrentUser(res.data);
//       console.log(res.data);
//     } catch (error) {
//       console.error('Error during login:', error);
//     }
//   };

//   const logout = async () => {
//     try {
//       await axios.post(`${API}auth/logout`);
//       setCurrentUser(null);
//     } catch (error) {
//       console.error('Error during logout:', error);
//     }
//   };

//   // Set the token in Axios headers for all subsequent requests
//   useEffect(() => {
//     const token = localStorage.getItem("access_token");
//     if (token) {
//       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     }
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("user", JSON.stringify(currentUser));
//   }, [currentUser]);

//   return (
//     <AuthContext.Provider value={{ currentUser, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
