// import axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import DocumentTitle from '../../component/DocumentTitle';



const Login = () => {
  DocumentTitle("Mawuli Stephen | Login")
  
  const [inputs, setInputs] = useState({
    username: "",
    // email: "",
    password: "",
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const {login}  = useContext(AuthContext);

  // console.log(login);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login( inputs);
      navigate("/write");
    } catch (err) {
      setError(err.response.data);
    }
  };
  return (
    <div className="auth">
      <h3>Login</h3>
      <form action="">
        <input type="text" placeholder="Username" name="username" onChange={handleChange} />
        {/* <input type="email" placeholder="email" name="email" onChange={handleChange} /> */}
        <input type="password" placeholder="Password" name="password" onChange={handleChange} />
        <button onClick={handleSubmit}>Login</button>
        {err && <p className="error">{err}</p>}
        <span>Don't have an account ? <Link to="/Register">Register</Link></span>
      </form>
    </div>
  )

}

export default Login;

// The one that works ends here 
  

// const Login = () => {
//     const [inputs, setInputs] = useState({
//         username: "",
//         password: "",
//     });
//     const [err, setError] = useState(null);

//     const navigate = useNavigate();

//     const login = useContext(AuthContext);


//     const handleChange = (e) => {
//         setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             await login(inputs)
//             navigate("/");
//         } catch (err) {
//             setError(err.response.data);
//         }
//     };
//     return (
//         <div className="auth">
//             <h1>Login</h1>
//             <form>
//                 <input
//                     required type="text"
//                     placeholder="username"
//                     name="username"
//                     onChange={handleChange}
//                 />

//                 <input
//                     required
//                     type="password"
//                     placeholder="password"
//                     name="password"
//                     onChange={handleChange}
//                 />
//                 <button onClick={handleSubmit}>Login</button>
//                 {err && <p >{err}</p>}
//                 <span>
//                     Don't you have an account? <Link to="/register">Register</Link>
//                 </span>
//             </form>
//         </div>
//     );
// };

// export default Login;
