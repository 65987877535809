import { useEffect, useState } from "react";
import Delete from "../../pages/../img/delete.png";
import Edit from "../../pages/../img/edit.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu from "../../component/Menu";
import axios from "axios";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import DOMPurify from "dompurify";
import DocumentTitle from '../../component/DocumentTitle';

const endPoint = process.env.REACT_APP_BASE_URL;

const Single = () => {
    DocumentTitle("Mawuli Stephen | Blog- details")

    const [post, setPost] = useState({});

    const location = useLocation();
    const navigate = useNavigate();

    const postId = location.pathname.split("/")[2];

    // console.log(postId, 'post id logged')

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${endPoint}posts/${postId}`);
                setPost(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [postId]);


    const handleDelete = async () => {
        try {
            await axios.delete(`${endPoint}posts/${postId}`);
            navigate("/")
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="single container">
            <div className="content">
                <img src={`${endPoint}uploads/${post?.img}`} alt={post.title}/>


                {/* console.log({post.userImg}); */}
                <div className="user">
                    {post.userImg && <img src={post.userImg} alt="" />}

                    <div className="info">
                        <span>{post.username}</span>
                        <p>Posted {moment(post.date).fromNow()}</p>
                    </div>


                    {currentUser && currentUser.username === post.username && (
                        <div className="edit">
                            <Link to={`/write?edit=2`} state={post}>
                                <img src={Edit} alt="" />
                            </Link>
                            <img onClick={handleDelete} src={Delete} alt="" />
                        </div>
                    )}

                </div>

                <h1>{post.title}</h1>
                <p
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(post.descrip),
                    }}
                ></p>

            </div>
            <Menu cat={post.cat} />
        </div>
    );
};

export default Single;