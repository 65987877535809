import React from "react";

import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useMediaQuery } from 'react-responsive';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from "../img/logo.png"
// // import {logo} from './logo.svg';



const Navbar = () => {
    const location = useLocation();
    const { currentUser, logout } = useContext(AuthContext);
    // Responsive Behaviour
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const handleMenuToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar sticky-top sticky-md-top sticky-sm-top">
            <div className="scroll-watcher"></div>

            <div className="container ">

                <div className="isMobile" >
                    <div className="logo">
                        <Link to="./">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>

                    <div className="humberger ">
                        <button className="primary" style={{width:"40px", height:'40px', padding: '0'}} onClick={handleMenuToggle}>
                        {isOpen ? <FaTimes /> : <FaBars />}

                        </button>
                        {/* <button className="fa-times fa-bars" style={{background:'var(--bg)'}} onClick={handleMenuToggle}>
                            {isOpen ? <FaTimes /> : <FaBars />}

                        </button> */}
                    </div>
                </div>

           
                {(isMobile && isOpen) || !isMobile ?
                    (
                        <>
                            {location.pathname === '/blog' ? (
                                // Show Blog's child menus and other menus when current page is Blog
                                <>
                                    <div className="isResponsive">
                                        <div className="links">
                                            <Link className="link" to="./?cat=technology"><h6>Technology</h6></Link>
                                            <Link className="link" to="./?cat=poetry"><h6>Poetry</h6></Link>
                                            <Link className="link" to="./?cat=marketing"><h6>Marketing</h6></Link>

                                        </div>

                                        {
                                            <div className="links">
                                                <Link className="link" to="./">
                                                    <h6>Home</h6>
                                                </Link>

                                                <Link className="link" to="./about">
                                                    <h6>About Me</h6>
                                                </Link>

                                                <span>
                                                    <h6>{currentUser?.username}</h6>
                                                </span>
                                                {currentUser ? <span onClick={logout}><h6>Logout</h6></span> : <Link className="link" to="./login"><h6>Login</h6></Link>}

                                            </div>
                                        }
                                    </div>
                                </>

                            ) : (
                                // Do not show Blog's child menus when current page is not Blog
                                <div className="isResponsive">
                                    <>
                                        {

                                            <div className="links">
                                                <Link className="link" to="./">
                                                    <h6>Home</h6>
                                                </Link>

                                                <Link className="link" to="./about">
                                                    <h6>About Me</h6>
                                                </Link>

                                                <Link className="link" to="./blog">
                                                    <h6>Blog</h6>
                                                </Link>

                                                <span>
                                                    <h6>{currentUser?.username}</h6>
                                                </span>
                                                {currentUser ? <span onClick={logout}><h6>Logout</h6></span> : <Link className="link" to="./login"><h6>Login</h6></Link>}

                                            </div>
                                        }
                                    </>
                                </div>
                            )}
                        </>
                    ) : null}

               
            </div>
        </nav>


    );
};

export default Navbar;
