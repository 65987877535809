import React from "react";
// import Logo from "../img/logo.png";
import Lionel from "../img/lionel.jpg"

const Footer = () => {
    return (
        <footer className="container">


            <div>
                <img className="selfie" src={Lionel} alt="Hero illustration" />
                {/* <div className="card">
                      <span>Mawuli Stephen Made it with ♥️ </span>  

                </div> */}

            </div>

            <div style={{  margin: '3px', }}>
                <div className="fbar" ></div>
                <ul>
                    <li><a href="https://twitter.com/@mawulistephens">Twitter</a></li>
                    <li><a href="mailto:mawulistephens@gmail.com">Email</a></li>
                    <li><a href="https://linkedin.com/mawulistephen">LinkedIn</a></li>

                </ul>
            </div>

            <div>
                {/* <img src={Logo} alt="" /> */}
                <span> Made with ♥️ and <b>React.js</b>.</span>
                {/* <span> <b>React.js And Node.Js</b> </span> */}
            </div>

        </footer>
    );
};


export default Footer