
import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  Outlet,
} from "react-router-dom";

import Navbar from './component/Navbar'
import Home from './pages/openRoute/Home';
import Blog from "./pages/openRoute/Blog";
import Register from './pages/openRoute/Register';
import Login from './pages/openRoute/Login';
import Single from './pages/openRoute/Single';
import About from './pages/openRoute/About';

import Write from './pages/dashboard/Write'
import Footer from './component/Footer'
import 'bootstrap/dist/css/bootstrap.css';

// import ReactGA from "react-ga4";

// Styles
// import './style.css'
import './styles/hero.css'
import './styles/card.css'
import './styles/style.css'
import './styles/tab.css'
// import './card.css'

// ReactGA.initialize("G-4P5SZVYVHJ");

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
};

const router = createBrowserRouter([
  {

    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/Blog",
        element: <Blog />
      },
      {
        path: "/Register",
        element: <Register />
      },
      {
        path: "/Login",
        element: <Login />
      },
      {
        path: "/posts/:id",
        element: <Single />
      },
      {
        path: "/Write",
        element: <Write />
      }
      ,
      {
        path: "/about",
        element: <About />
      }
    ]
  },
]);

function App() {
  return (
    // <div className="app">
      <div className="section">
        <RouterProvider router={router}/>
      </div>
    // </div>
  );
}


export default App;
