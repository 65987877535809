import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import mawuli from "../../pages/../img/mawuli.jpg";
import DocumentTitle from '../../component/DocumentTitle';

const endPoint = process.env.REACT_APP_BASE_URL

const Home = () => {
    DocumentTitle("Mawuli Stephen | Home")

    const [posts, setPosts] = useState([]);

    const cat = useLocation().search
    // Change this to the number of posts you want to display
    const limit = 4;

    // Sort posts from new to old
    const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Limit the number of posts
    const limitedPosts = sortedPosts.slice(0, limit);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${endPoint}posts${cat}`);
                setPosts(res.data);
                // console.log(res.data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };
        fetchData();
    }, [cat]);


    const getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
    }


    return (
        <div className="home">
            <div className="container" id="content">
                <hr />
                <hr />
                <hr />
                <div className="section bg-blur ">

                    <div className="hero">
                        <section className="hero-left">
                            <h1>A versatile individual who loves to create solutions for people and businesses.</h1>
                            <p>
                                Combined 10 years of working experience as a Marketing professional and Software developer
                            </p>

                            <Link to={`/about`}><button className="primary" >Read More</button ></Link>
                            {/* <a href="./about" className="primary">Read More</a> */}
                        </section>

                        <section className="right">

                            <img src={mawuli} alt="Hero illustration" />
                        </section>
                    </div>
                </div>


                <hr />
                <hr />
                <hr />

                <div className="middle section " >
                    <div className="heroColor">
                        <div className="col text-start" style={{ padding: '10px', }}>
                            <h3> Experties & Hobbies</h3>
                            <div className="row">
                                <div className="col">
                                    <div className="bg-blur-2" style={{ position: 'relative' }}>
                                        <button>Web development</button>
                                        <button>UI/Ux design</button>
                                        <button>Sales</button>
                                        <button>Advertising</button>
                                        <button>Guitar</button>
                                        <button>Writing</button>
                                        <button>Poetry</button>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>


                <hr />
                <hr />
                <hr />



                <div className=" mb-4 subtitle" >

                    <h3 className="text-start">Recent Write-ups</h3>
                    <a href="./blog">Read More</a>


                </div>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 ">

                    {limitedPosts.map((post) => (

                        <div key={post.id}>

                            <div className="blog-card content post" style={{ marginBottom: '20px' }} >

                                 <img className="blog-img" src={`${endPoint}uploads/${post.img}`} alt={post.title} />
                                 {/* <Link to={`/posts/${post.id}`}></Link> */}
                                <div className="text-overlay ">
                                    <h4 className="text-bold">{post.title.substring(0, 30)}</h4>
                                    <p>{getText(post.descrip).substring(0, 150)}</p>
                                    <Link to={`/posts/${post.id}`}><button className="primary" style={{ padding: '5px' }}>Read More</button ></Link>
                                </div>

                            </div>

                        </div>
                    ))}

                </div>



                <hr />
                <hr />
                <hr />

            </div>
        </div>
    );
};

export default Home;